// 菜单，侧边栏
import smartCard from './modules/smart-card';
import customManage from './modules/custom-manage';

// 系统
import log from './modules/log';
export default [
// {
//     path: '/test',
//     title: '基础',
//     header: 'home',
// },
smartCard, customManage, log];