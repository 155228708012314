const pre = "/custom-manage/";
export default {
  path: "/custom-manage",
  title: "客户管理",
  header: "home",
  icon: "md-contact",
  children: [{
    path: `${pre}customer-label`,
    title: "客户标签"
  }]
};