import BasicLayout from "@/layouts/basic-layout";
const meta = {
  auth: true
};
const pre = "marketing-";
export default {
  path: "/marketing",
  name: "marketing",
  redirect: {
    name: `${pre}customer-fission`
  },
  meta,
  component: BasicLayout,
  children: [{
    path: "customer-fission",
    name: `${pre}customer-fission`,
    meta: {
      ...meta,
      title: "客户裂变"
    },
    component: () => import("@/pages/marketing/customer-fission")
  }, {
    path: "customer-fission/count",
    name: `${pre}customer-fission-count`,
    meta: {
      ...meta,
      title: "客户裂变-数据统计"
    },
    component: () => import("@/pages/marketing/customer-fission/count.vue")
  }, {
    path: "customer-fission/create",
    name: `${pre}customer-fission-create`,
    meta: {
      ...meta,
      title: "创建活动"
    },
    component: () => import("@/pages/marketing/customer-fission/create.vue")
  }, {
    path: "customer-fission/update",
    name: `${pre}customer-fission-update`,
    meta: {
      ...meta,
      title: "编辑活动"
    },
    component: () => import("@/pages/marketing/customer-fission/create.vue")
  }, {
    path: "customer-fission/invited",
    name: `${pre}customer-fission-invited`,
    meta: {
      ...meta,
      title: "客户裂变-邀请客户参与"
    },
    component: () => import("@/pages/marketing/customer-fission/invited.vue")
  }, {
    path: "group-fission",
    name: `${pre}group-fission`,
    meta: {
      ...meta,
      title: "群裂变"
    },
    component: () => import("@/pages/marketing/group-fission")
  }, {
    path: "group-fission/count",
    name: `${pre}group-fission-count`,
    meta: {
      ...meta,
      title: "群裂变-数据统计"
    },
    component: () => import("@/pages/marketing/group-fission/count.vue")
  }, {
    path: "group-fission/invited",
    name: `${pre}group-fission-invited`,
    meta: {
      ...meta,
      title: "群裂变-邀请客户参与"
    },
    component: () => import("@/pages/marketing/group-fission/invited.vue")
  }, {
    path: "group-fission/create",
    name: `${pre}group-fission-create`,
    meta: {
      ...meta,
      title: "创建活动"
    },
    component: () => import("@/pages/marketing/group-fission/create.vue")
  }, {
    path: "questionnaire-form",
    name: `${pre}questionnaire-form`,
    meta: {
      ...meta,
      title: "问卷表单"
    },
    component: () => import("@/pages/marketing/questionnaire-form")
  },
  // {
  //   path: "questionnaire-form/create",
  //   name: `${pre}questionnaire-form-create`,
  //   meta: {
  //     ...meta,
  //     title: "新建表单",
  //   },
  //   component: () => import("@/pages/marketing/questionnaire-form/create.vue"),
  // },
  {
    path: "questionnaire-form/info",
    name: `${pre}questionnaire-form-info`,
    meta: {
      ...meta,
      title: "问卷表单-数据详情"
    },
    component: () => import("@/pages/marketing/questionnaire-form/info.vue")
  }]
};