import BasicLayout from "@/layouts/basic-layout";
const meta = {
  auth: true
};
const pre = "smart-card-";
export default {
  path: "/smart-card",
  name: "smart-card",
  redirect: {
    name: `${pre}card-management`
  },
  meta,
  component: BasicLayout,
  children: [{
    path: "card-management",
    name: `${pre}card-management`,
    meta: {
      ...meta,
      title: "名片管理"
    },
    component: () => import("@/pages/smart-card/card-management")
  }, {
    path: "card-management/setting",
    name: `${pre}card-management-setting`,
    meta: {
      ...meta,
      title: "名片管理"
    },
    component: () => import("@/pages/smart-card/card-management/card-setting")
  }]
};