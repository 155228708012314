/* unplugin-vue-components disabled */import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  key: 0,
  src: "https://qiniu.hp.gimyingao.com/sales/image/task-systems1426b3fb-bed5-97b8-c6c2-acc28f97bfb9.png"
};
const _hoisted_2 = {
  key: 1,
  src: "https://siemens-wecom-test.gimyingao.com/dev/material/20240506151528000000sp3OqLkM/%E7%BC%96%E7%BB%84%2029%402x.png"
};
const _hoisted_3 = {
  key: 2,
  src: "https://siemens-wecom-test.gimyingao.com/dev/material/20240506151528000000sp3OqLkM/%E7%BC%96%E7%BB%84%2029%402x.png"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_i_link = _resolveComponent("i-link");
  return _openBlock(), _createBlock(_component_i_link, {
    class: _normalizeClass(["i-layout-header-logo", {
      'i-layout-header-logo-stick': !_ctx.isMobile
    }]),
    to: "/"
  }, {
    default: _withCtx(() => [_ctx.isMobile ? (_openBlock(), _createElementBlock("img", _hoisted_1)) : _ctx.headerTheme === 'light' ? (_openBlock(), _createElementBlock("img", _hoisted_2)) : (_openBlock(), _createElementBlock("img", _hoisted_3))]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["class"]);
}