/* unplugin-vue-components disabled */import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue";
const _hoisted_1 = ["src", "alt"];
const _hoisted_2 = ["src", "alt"];
const _hoisted_3 = {
  key: 0
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Icon = _resolveComponent("Icon");
  return _openBlock(), _createElementBlock("span", {
    class: _normalizeClass(["i-layout-menu-side-title", {
      'i-layout-menu-side-title-with-collapse': $props.collapse
    }])
  }, [$options.withIcon ? (_openBlock(), _createElementBlock("span", {
    key: 0,
    class: _normalizeClass(["i-layout-menu-side-title-icon row ai-center", {
      'i-layout-menu-side-title-icon-single': $props.hideTitle
    }])
  }, [$props.menu.icon ? (_openBlock(), _createBlock(_component_Icon, {
    key: 0,
    type: $props.menu.icon,
    size: "16"
  }, null, 8 /* PROPS */, ["type"])) : $props.menu.custom ? (_openBlock(), _createBlock(_component_Icon, {
    key: 1,
    custom: $props.menu.custom
  }, null, 8 /* PROPS */, ["custom"])) : _createCommentVNode("v-if", true), _createCommentVNode(" <img :src=\"menu.img\" v-else-if=\"menu.img\" /> "), $props.menu.title === $data.title || $props.menu.title === $data.selectTit ? (_openBlock(), _createElementBlock("img", {
    key: 2,
    src: $props.menu.imgActive,
    alt: $props.menu.title
  }, null, 8 /* PROPS */, _hoisted_1)) : (_openBlock(), _createElementBlock("img", {
    key: 3,
    src: $props.menu.img,
    alt: $props.menu.title
  }, null, 8 /* PROPS */, _hoisted_2))], 2 /* CLASS */)) : _createCommentVNode("v-if", true), !$props.hideTitle ? (_openBlock(), _createElementBlock("span", {
    key: 1,
    class: _normalizeClass(["i-layout-menu-side-title-text", {
      'i-layout-menu-side-title-text-selected': $props.selected,
      'i-layout-menu-side-title-text-with-subtitle': $props.menu.subtitle,
      'i-layout-menu-side-title-text-with-icon': $options.withIcon
    }]),
    onClick: _cache[0] || (_cache[0] = $event => $options.selectMenu($props.menu))
  }, [_createTextVNode(_toDisplayString(_ctx.tTitle($props.menu.title)), 1 /* TEXT */), $props.menu.subtitle ? (_openBlock(), _createElementBlock("em", _hoisted_3, _toDisplayString(_ctx.tTitle($props.menu.subtitle)), 1 /* TEXT */)) : _createCommentVNode("v-if", true)], 2 /* CLASS */)) : _createCommentVNode("v-if", true)], 2 /* CLASS */);
}