const pre = "/smart-card/";
export default {
  path: "/smart-card",
  title: "智能名片",
  header: "home",
  icon: "md-contact",
  children: [{
    path: `${pre}card-management`,
    title: "名片管理"
  }, {
    path: `${pre}card-management/setting`,
    title: "名片设置",
    hide: true
  }]
};